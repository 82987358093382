import React from "react";

const PageNotFound = () => {
  return (
    <>
      <div className='sm:px-8 mt-16 sm:mt-32'>
        <section className='relative px-4 sm:px-8 lg:px-12'>
          <div className='mx-auto max-w-2xl lg:max-w-5xl'>
            <h1>🤨 401 - This page does not exist 🤔</h1>
            <h2>Try to go 🏠</h2>
            <a
              href='/'
              className='inline-flex items-center px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 transition ease-in-out duration-150'
            >
              Go Home
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default PageNotFound;
