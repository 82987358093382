import { NavLink } from "react-router-dom";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { HiXMark, HiBars3 } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

export default function Navbar() {
  let { pathname } = useLocation();
  return (
    <Disclosure as='nav' className='bg-white shadow dark:bg-black'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-4 sm:p-4 lg:px-8'>
            <div className='flex h-20 justify-between'>
              <nav
                id='main-menu'
                className='flex flex-col items-center justify-center mx-auto space-y-4'
              >
                <div className='flex flex-shrink-0 items-center'>
                  <NavLink
                    href='/'
                    title='Go to Home'
                    className='flex space-x-2 items-center'
                  >
                    <svg
                      width='50'
                      height='51'
                      viewBox='0 0 200 201'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill='#f3ca4c'
                        stroke='none'
                        d='M 156.916656 154.333344 C 151.229156 147.104172 147.536469 138.458344 141.140625 131.708344 C 125.479156 115.197937 103.645828 117.911469 83.041672 115.401062 C 78.682297 114.869812 73.375 113.380219 72.510422 109.078125 C 70.854172 100.8125 86.171875 96.40625 91.588547 93.875 C 99.973953 89.947937 108.588531 85.864594 114.822906 78.786469 C 120.223969 72.651062 119.817719 67.796875 113.171875 62.130219 C 101.46875 52.151062 61.463547 45.166687 38.197922 54.036469 C 52.416672 35.463562 74.807297 23.479187 100.010422 23.479187 C 142.979156 23.479187 177.8125 58.3125 177.8125 101.286469 C 177.8125 121.791687 169.875 140.4375 156.916656 154.333344 Z M 99.604172 179.088547 C 56.723953 178.869797 22.203125 144.166672 22.203125 101.286469 C 22.203125 100.932312 22.208328 100.578125 22.213547 100.229187 C 22.213547 100.208344 22.213547 100.1875 22.213547 100.166687 C 22.260422 96.713562 22.739578 93.270844 23.630203 89.932312 C 26.723953 78.338562 34.770828 68.364594 45.28125 62.630219 C 58.291672 55.536469 73.875 56.671875 87.614578 60.3125 C 93.020828 61.739594 104.901047 65.635437 99.682297 73.302094 C 97.640625 76.296875 94.005203 77.848969 90.729172 79.104187 C 86.427078 80.75 81.859375 81.546875 77.5 83.026062 C 70.151047 85.515625 62.109375 88.25 56.723953 94.130219 C 52.854172 98.359375 51.171875 103.838562 51.145828 109.505219 C 51.130203 112.796875 51.791672 116.088562 53.057297 119.15625 C 58.583328 132.494797 72.723953 137.958344 85.942703 140.520844 C 96.291672 142.53125 110.375 141.734375 117.354156 150.901047 C 122.59375 157.786469 125.703125 165.71875 129.713531 173.213547 C 120.442719 177.046875 110.270844 179.145844 99.604172 179.088547 Z M 100.010422 1.276062 C 44.776047 1.276062 0 46.052094 0 101.286469 C 0 156.520844 44.776047 201.291672 100.010422 201.291672 C 155.239594 201.291672 200.015625 156.520844 200.015625 101.286469 C 200.015625 46.052094 155.239594 1.276062 100.010422 1.276062'
                      />
                    </svg>
                    <span className='text-2xl md:text-4xl text-[#f3ca4c] font-serif'>
                      Golden Orinoco
                    </span>
                  </NavLink>
                </div>
                <div className='hidden sm:ml-6 sm:flex sm:space-x-8'>
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <NavLink to='/' className='main-menu-item'>
                    Home
                  </NavLink>
                  <NavLink to='/about-us' className='main-menu-item'>
                    About Us
                  </NavLink>
                  <NavLink to='/contact-us' className='main-menu-item'>
                    Contact Us
                  </NavLink>
                </div>
              </nav>
              <div className='-mr-2 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <DisclosureButton className='inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:text-gray-400 dark:hover:bg-gray-900'>
                  <span className='sr-only'>Open Menu</span>
                  {open ? (
                    <HiXMark className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <HiBars3 className='block h-6 w-6' aria-hidden='true' />
                  )}
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className='sm:hidden'>
            <div className='space-y-1 pb-3 pt-2'>
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <DisclosureButton
                as='a'
                href='/'
                className={clsx(
                  "mobile-main-menu-item",
                  pathname === "/" && "mobile-main-menu-item-active"
                )}
              >
                Home
              </DisclosureButton>
              <DisclosureButton
                as='a'
                href='/about-us'
                className={clsx(
                  "mobile-main-menu-item",
                  pathname === "/about-us" && "mobile-main-menu-item-active"
                )}
              >
                About Us
              </DisclosureButton>
              <DisclosureButton
                as='a'
                href='/contact-us'
                className={clsx(
                  "mobile-main-menu-item",
                  pathname === "/contact-us" && "mobile-main-menu-item-active"
                )}
              >
                Contact Us
              </DisclosureButton>
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}
