import { Toaster } from "react-hot-toast";

import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Root from "./components/Root";

import PageNotFound from "./pages/PageNotFound";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Root />} errorElement={<PageNotFound />}>
      <Route index element={<Home />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='*' element={<PageNotFound />} />
    </Route>
  )
);

function App() {
  return (
    <>
      <Toaster position='top-right' />
      <RouterProvider router={appRouter} />
    </>
  );
}

export default App;
