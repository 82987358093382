const AboutUs = () => {
  return (
    <>
      <div className='sm:px-8 mt-16 sm:mt-32'>
        <section className='relative px-4 sm:px-8 lg:px-12'>
          <div className='mx-auto max-w-2xl lg:max-w-5xl'>
            <div className='grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12'>
              <div className='lg:pl-20 group'>
                <div className='max-w-xs px-2.5 lg:max-w-none'>
                  <img
                    src='/images/us.webp'
                    alt='Us'
                    loading='lazy'
                    width='800'
                    height='800'
                    className='aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover group-hover:rotate-0 group-hover:scale-[1.04]'
                  />
                </div>
                <div className='absolute rotate-3 p-1 bg-slate-200/80 text-slate-400 group-hover:rotate-0 group-hover:ml-5 group-hover:mt-2 dark:bg-black'>
                  <p className='text-xs'>
                    Photo by{" "}
                    <a href='https://unsplash.com/@homajob?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash'>
                      Scott Graham
                    </a>{" "}
                    on{" "}
                    <a href='https://unsplash.com/photos/person-holding-pencil-near-laptop-computer-5fNmWej4tAA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash'>
                      Unsplash
                    </a>
                  </p>
                </div>
              </div>
              <div className='lg:order-first lg:row-span-2'>
                <h1>
                  Golden Orinoco: An Ecommerce Company Dedicated to Excellence
                  in Customer Service
                </h1>
                <div className='mt-6 space-y-7 text-base'>
                  <p>
                    Welcome to Golden Orinoco, where excellence in customer
                    service is our top priority. As a leading ecommerce company,
                    we are dedicated to providing our US customers with an
                    unparalleled shopping experience. Our commitment to quality,
                    reliability, and customer satisfaction sets us apart in the
                    industry. We offer a wide range of high-quality products,
                    carefully curated to meet the diverse needs and preferences
                    of our customers.
                  </p>
                  <p>
                    Our customer service team is always here to help. With a
                    focus on prompt and friendly support, we are committed to
                    addressing your questions and concerns with the utmost care
                    and attention. Your satisfaction is our success, and we are
                    here to make sure you have a positive experience every time
                    you shop with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
