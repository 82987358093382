const Footer = () => {
  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
  return (
    <footer className='flex-shrink-0'>
      <p className='mt-6 text-sm text-slate-500 sm:mt-0'>
        Copyright © {getCurrentYear()} · Golden Orinoco, LLC · Wilmington, DE,
        USA
      </p>
    </footer>
  );
};

export default Footer;
