const MiniAbout = () => {
  return (
    <section
      className='pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32 dark:bg-black'
      aria-label='Razones para contratarnos'
    >
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div id='features' className='mx-auto max-w-2xl'>
          <h2>
            Multi-Platform E-commerce Company: Operating Multiple Online Brands
          </h2>
          <p className='mt-4'>
            Our company specializes in e-commerce retail, operating across
            multiple platforms including Amazon FBA, Etsy, and Shopify. We
            manage a diverse portfolio of e-commerce websites, each catering to
            specific niche markets. Our primary focus is the sleep wellness
            market, where we offer a carefully curated catalog of premium
            products designed to enhance sleep quality and overall well-being.
          </p>
          <p className='mt-4'>
            Across our various online stores, our product range encompasses
            several key categories: luxury bedding, sleep aids, wellness
            supplements, aromatherapy solutions, circadian rhythm optimization
            tools, and comfort accessories. These items are sourced from
            reputable manufacturers and undergo stringent quality control to
            ensure customer satisfaction.
          </p>
          <p className='mt-4'>
            To market our products, we employ a comprehensive digital strategy.
            This includes e-commerce platform optimization, social media
            marketing, content creation through sleep health blogs, and
            partnerships with wellness influencers. Our goal is to establish our
            e-commerce brands as trusted names in their respective industries,
            providing consumers with high-quality solutions for improved sleep,
            relaxation, and overall wellness.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MiniAbout;
