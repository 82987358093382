import Intro from "../components/Home/Intro";
import MiniAbout from "../components/Home/MiniAbout";

const Home = () => {
  return (
    <>
      <Intro />
      <MiniAbout />
    </>
  );
};

export default Home;
